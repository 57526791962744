import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  MicroCmsLayout,
  LWrap,
  LRestaurantContact,
} from '../../../components/_index';
import PickupPageLayout from '../../../components/_extra/pickup_layout';

// markup
const SubPage = ({ data }: any) => {
  const news = data.microcmsPickupYrph;
  return (
    <Layout>
      <SEO
        title={`${news.title}`}
        description={news.description && news.description.replace(/\r?\n/g, '')}
        ogimage={news.eyecatch?.url}
      />
      <CBreadCrumb
        data={{
          parent: [{ label: 'レストラン', path: '/restaurants/' }],
          current: {
            label: news.title,
          },
        }}
      />
      <PickupPageLayout data={news} />
      <section className="l_sect">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
            <li>料金には消費税、サービス料が含まれます。</li>
            <li>料理の表示価格は、特別な記載がない限り1名様分の料金です。</li>
            <li>
              テイクアウト商品の料金には消費税（8％）のみ含まれます。コフレ店内をご利用の場合は、サービス料を加算、消費税は10％となります。
            </li>
            <li>入荷状況によりメニュー内容に変更がある場合がございます。</li>
            <li>
              「神奈川県公共的施設における受動喫煙防止条例」に基づきレストラン・バーを全席禁煙とさせていただいております。
            </li>
            <li>
              ご入店の際は、タンクトップ・短パン・ビーチサンダルなどはご遠慮いただいております。
            </li>
          </ul>
        </LWrap>
      </section>
      <LRestaurantContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($id: String!) {
    microcmsPickupYrph(id: { eq: $id }) {
      classification
      description
      eyecatch {
        height
        url
        width
      }
      fee
      link {
        blank
        href
      }
      pdf {
        url
      }
      period
      pickupYrphId
      restaurants
      pagetype
      title
      contents {
        fieldId
        heading_text
        section_title
        section_subtitle
        wysiwyg
        box
        caption
        label
        table
        youtube
        htmlcode
        type
        align
        image {
          url
          width
          height
        }
        images {
          caption
          image {
            url
            width
            height
          }
          link {
            href
            blank
          }
        }
        keyvalue {
          fieldId
          keyname
          value
          value2
        }
        list {
          value
        }
        tile {
          image {
            url
            width
            height
          }
          heading_text
          wysiwyg
        }
        card {
          btn {
            fieldId
            label
            link {
              blank
              href
            }
            margin {
              margin
            }
            pdf {
              url
            }
          }
        }
        link {
          href
          blank
        }
        pdf {
          url
        }
        margin {
          margin
        }
        accordion {
          title
          contents {
            fieldId
            heading_text
            wysiwyg
            box
            caption
            label
            table
            youtube
            htmlcode
            type
            align
            image {
              url
              width
              height
            }
            link {
              href
              blank
            }
            pdf {
              url
            }
            margin {
              margin
            }
          }
        }
        left {
          fieldId
          heading_text
          wysiwyg
          box
          caption
          label
          table
          youtube
          htmlcode
          type
          align
          image {
            url
            width
            height
          }
          keyvalue {
            fieldId
            keyname
            value
            value2
          }
          link {
            href
            blank
          }
          pdf {
            url
          }
          margin {
            margin
          }
        }
        right {
          fieldId
          heading_text
          wysiwyg
          box
          caption
          label
          table
          youtube
          htmlcode
          type
          align
          image {
            url
            width
            height
          }
          keyvalue {
            fieldId
            keyname
            value
            value2
          }
          link {
            href
            blank
          }
          pdf {
            url
          }
          margin {
            margin
          }
        }
      }
    }
  }
`;
