import React from "react"
import { Script } from 'gatsby';
import { Helmet } from "react-helmet"
import { LWrap, MicroCmsImage } from "../../components/_index"
import formatDateJa from "../../utils/format-date-ja"
import periodText from "../../utils/period-text"
import MicroCmsContents from "./microcms_contents"

const PickupLayout = ({ data }: { data: any }) => {

  //リダイレクト判定
  const linksrc = data.pdf?.url ? data.pdf.url : data.link?.href ? data.link.href : undefined

  return (
    <>
      <Script dangerouslySetInnerHTML={{
        __html: `
(function(){
    const sentenceAtag = document.querySelectorAll('.js_articleSentence a');
    for (let i = 0; i < sentenceAtag.length; i++) {
      const target = sentenceAtag[i].getAttribute('target')
      const icon_blank = document.createElement("i")
      icon_blank.className = 'icon-blank';
      const pdf_check = /\.pdf$/.test(sentenceAtag[i].getAttribute('href'))
      const icon_pdf = document.createElement("i")
      icon_pdf.className = 'icon-pdf';
      if(pdf_check){
        sentenceAtag[i].prepend(icon_pdf);
      }else if(target == "_blank"){
        sentenceAtag[i].appendChild(icon_blank);
      }
    }
})(document)
        `}} />
      {linksrc && <Helmet><meta http-equiv="refresh" content={`0;URL=${linksrc}`} /></Helmet>}
      <section className="l_sect02">
        <LWrap>
          <div className="c_articleHeading">
            <ul className="labelList">
              {data.classification.map((item: any, index: number) => {
                return (
                  <li key={index}><span className="c_label">{item}</span></li>
                )
              })}
            </ul>
            <h2 className="title">{data.title}</h2>
          </div>
          {data.description && <div>
            <p>{data.description.split('\n').map((t: string) => (<>{t}<br /></>))}</p>
          </div>}
          {data.eyecatch && (
            <figure className="c_articleKV">
              <MicroCmsImage image={data.eyecatch} alt="" loading="lazy" />
            </figure>
          )}
          <div className="c_definition">
            {data.period && <dl>
              <dt className="c_definition_title">期間</dt>
              <dd className="c_definition_text">{data.period.split('\n').map((t: string) => (<>{t}<br /></>))}</dd>
            </dl>}
            {data.fee && <dl>
              <dt className="c_definition_title">料金</dt>
              <dd className="c_definition_text">{data.fee.split('\n').map((t: string) => (<>{t}<br /></>))}</dd>
            </dl>}
          </div>
          <MicroCmsContents data={data.contents} />
        </LWrap>
      </section>
    </>
  )
}
export default PickupLayout;
